
.areas-container {
  
  background-color: #F3F4F6;
  padding: 48px;
}



.media-card-title {
  font-weight: 800 !important;
  font-size: 40px !important;
  line-height: 60px !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.media-card-title-small {
  font-weight: 800 !important;
  font-size: 25px !important;
  line-height: 40px !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

}

/* .media-card-title-small:hover{
  color:#bfc4cd;
} */

.card-container {
  display: flex;
  justify-content: space-around; /* Adjust to space-between if you want more space between cards */
  flex-wrap: wrap; /* Allow cards to wrap to the next line if there is not enough space */
}

.link {
  text-decoration: none;
}

.link-area {
  text-decoration: none;
}


@media (max-width: 480px) {
  .link-area {
    margin-bottom: 20px;
  }
}