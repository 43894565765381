.area-description {
  margin-top: 50px;
  margin-bottom: 50px;
  color: #374151;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px
}

.grade-header {
  margin-top: 50px;
  color: #374151;
  font-size: 24px;
  font-weight: 400;
}

.boulder-item {
  color: #000000;
  font-size: 14px;
  font-weight: 400;

}
.boulders-table {
  margin-bottom:100px
}

.boulder-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding-left: 10px
}

.boulder-row:hover {
 background: #FFFFFF;
}

.boulder-column, .boulder-column-circuit, .boulder-column-name
{
  flex: 1;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding-left: 40px; 
}


.boulder-column-description {
  flex: 4;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding-left: 40px;
  
}

.circle {
    width: 1.5rem;  
    height: 1.5rem; 
    border-radius: 9999px; 
    /* margin: 5px;  */
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.circle-text {
  color:#FFF;
  z-index: 1000;
  font-size: 16px;
  line-height:24px;
  font-weight: 400;
}

.map-container {
  height: 400px;
  }
   
.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  font-size: 16px;
  z-index: 1; 
  margin-bottom: 12px; 
  border-radius: 4px;
}



.boulder-image-style {
  width: 100%;
  height: 100%;
};

.boulder-card {
  position: relative;
  max-width: 800px;
  height: 600px;
  margin-top: 20px;
  padding: 0;
}

@media (max-width: 390px) {
  .boulder-column-circuit {
    padding-left: 0px;
    font-size: 12px;
  }

  .boulder-column-name {
    padding-left: 0px;
    font-size: 12px;
    flex: 3;
  }
  .boulder-column-description {
    /* flex: 4;
    box-sizing: border-box;
    color: #000000; */
    font-size: 10px;
    font-weight: 400;
    /* padding-left: 40px;  */
  }
}

.back-link {
  color: #374151;
  text-decoration: none;
  font-size: 18px;
}

.back-link:hover {
  color: #C56844;

}
  





