
.boulder-description {
  margin-top: 20px !important;
  margin-bottom: 150px !important;
}

.boulder-box {
  max-width: 800px;
  
}

.boulder-grade {
  float: right;
  color: rgb(107, 114, 128)
}


.boulder-card {
  position: relative;
  max-width: 800px;
  height: 600px;
  margin-top: 20px;
  padding: 0;
}

@media (max-width: 390px) {
  .boulder-card {
    height: auto;
  }
}